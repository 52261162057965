
import { defineComponent, ref } from "vue";
import XLSX from "xlsx";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import userApi from "@/core/services/User";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter } from "vue-router";
import buttonHandle from "@/shared.js";

export default defineComponent({
    name: "users",
    components: {},
    data: function () {
        return {
            users: [],
            filter: {
                identity: "",
                email: "",
                user_type: "",
                status: "",
            },
            userFileData: [],
            loading: true,
        };
    },
    methods: {
        handleFile(e) {
            const selectedFile = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (ev) => {
                if (ev.target) {
                    const bstr = ev.target.result;
                    const wb = XLSX.read(bstr, { type: "binary" });
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    this.userFileData = XLSX.utils.sheet_to_json(ws, { header: 2 });
                }
            };
            reader.readAsBinaryString(selectedFile);
        },
        addUsers() {
            buttonHandle.handleWaitingButton(this.submitButton);
            userApi
                .addUsers({ users: this.userFileData })
                .then(() => {
                    buttonHandle.handleValidatedButton(this.submitButton);
                    setTimeout(() => {
                        this.fetchUsers();
                    }, 500);
                })
                .catch(() => {
                    buttonHandle.handleErrorButton(this.submitButton);
                    this.displayErrorMsg("Une erreur est survenue lors de la mise à jour des utilisateurs.");
                });
        },
        resetFilter() {
            this.filter = {
                identity: "",
                email: "",
                user_type: "",
                status: "",
            };
            this.fetchUsers();
        },
        fetchUsers() {
            this.loading = true;
            userApi
                .getUsers(this.filter)
                .then((response) => {
                    this.users = response.data["users"];
                    this.loading = false;
                })
                .catch(() => {
                    this.displayErrorMsg("Une erreur est survenue lors de la récupération des utilisateurs.");
                });
        },
        displayErrorMsg(msg: string) {
            Swal.fire({
                text: msg,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Fermer",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                },
            });
        },
    },

    setup() {
        const { t } = useI18n();
        const router = useRouter();
        const submitButton = ref<HTMLElement | null>(null);
        setCurrentPageBreadcrumbs(t("pages.dashboard.users"), []);

        function editUser(user: any) {
            userApi.getSpecificUser(user.id).then(() => {
                router.push({ name: "usersdetail", params: { user_id: user.id }, query: { admin: "false" } });
            });
        }
        return { t, editUser, submitButton };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.dashboard.users"), []);
        },
    },
    created() {
        this.fetchUsers();
    },
});
